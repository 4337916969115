import { Controller } from "@hotwired/stimulus"
import "../initializers/rich_text_editor"

export default class extends Controller {
  static targets = ["messageTemplateSelect", "variable", "attachmentButton"]

  connect() {
    this.editorElement = this.element.querySelector("trix-editor")
    this.initAttachments()
  }
  // We don't store this.editor in the connect() method anymore, as it would return 'undefined'.
  // See https://github.com/basecamp/trix/issues/1038
  get editor() {
    return this.editorElement.editor
  }

  initAttachments() {
    if(!this.hasAttachmentButtonTarget) return

    this.attachmentButtonTarget.addEventListener("click", function(e) {
      e.preventDefault()
      document.querySelector("#message_attachments").click()
    })

    const $attachmentContainer = $(this.element.querySelector(".js-message-attachments"))
    new MessageAttachments($attachmentContainer);
  }

  preventAttachment(event) {
    event.preventDefault()
  }

  setColor(event) {
    this.editor.activateAttribute("foregroundColor", event.target.value);
  }

  insertMessageTemplate(event) {
    event.preventDefault()

    if(this.hasMessageTemplateSelectTarget) {
      this.editor.loadDocument() // clear attachments
      this.editor.loadHTML(this.selectedMessageTemplateContent) // replace all existing content
    }
  }

  insertVariable(event) {
    let variableName = event.target.dataset.variableName
    let initialRange = this.editor.getSelectedRange()
    this.editor.insertHTML(variableName)
    this.editor.selectionManager.unlock();
    this.editor.setSelectedRange(initialRange[0] + variableName.length)
  }

  get selectedMessageTemplateContent() {
    if (this.hasMessageTemplateSelectTarget) {
      let selectedIndex = this.messageTemplateSelectTarget.selectedIndex;
      return this.messageTemplateSelectTarget.options[selectedIndex].value
    }
  }
}
