import { Controller } from "@hotwired/stimulus"
import VenueCardMapMarker from "../helpers/venue_card_map_marker"
import GoogleMapMarker from "../helpers/google_maps_marker"

export default class extends Controller {
  connect() {
    if (this.element.querySelector("#map")) {
      this.initVenueCards()
      this.initMap()
    }

    // todo remove when we replace index.js.erb with trubo stream
    window.addEventListener("search-refreshMarkers", this.refreshMarkers.bind(this, true));
    window.addEventListener("init-map-when-was-activity", this.initMapWhenWasActivity.bind(this));
  }

  initMapWhenWasActivity() {
    this.initVenueCards()
    this.initMap()
  }

  initVenueCards() {
    this.venueCards = [...this.element.querySelectorAll(".venue-card")].map((venueCard) => new VenueCardMapMarker(venueCard))
  }

  initMap() {
    const mapBounds = this.element.querySelector("#mapBounds")
    const centerLat = mapBounds.dataset.centerLat
    const centerLng = mapBounds.dataset.centerLng
    const mapCanvas = this.element.querySelector("#map")

    const closeVenueInfobox = () => MapMarker.infobox?.close()
    const centerPoint = new google.maps.LatLng(centerLat, centerLng)
    this.map = new google.maps.Map(mapCanvas, google.maps.Map.mapOptions(centerPoint))

    google.maps.event.addListenerOnce(this.map, 'idle', () => { this.addMarkers(true)})
    google.maps.event.addListener(this.map, "click", closeVenueInfobox)
    google.maps.event.addListener(this.map, "dragend", closeVenueInfobox)
    google.maps.event.addListener(this.map, "zoom_changed", closeVenueInfobox)
  }

  refreshMarkers() {
    this.initVenueCards()
    this.addMarkers(false)
  }

  addMarkers(fitBounds) {
    this.disableSearchOnMap()
    this.removeMarkers()
    this.markers = this.venueCards.map((venueCard) => new GoogleMapMarker(venueCard, this.map))

    if (this.markers.length > 0) {
      const bounds = new google.maps.LatLngBounds()
      this.markers.forEach((marker) => bounds.extend(marker.getPosition()))

      if (fitBounds){
        this.map.panTo(bounds.getCenter())
        this.map.fitBounds(bounds)
      }
    }

    this.enableSearchOnMap()
  }

  removeMarkers() {
    this.markers?.forEach((marker) => marker.detachFromMap())
    this.markers = []
  }

  disableSearchOnMap() {
    if (this.dragendListener) {
      google.maps.event.removeListener(this.dragendListener)
      this.dragendListener = null
    }
    if (this.zoomChangedListener) {
      google.maps.event.removeListener(this.zoomChangedListener)
      this.zoomChangedListener = null
    }
  }

  enableSearchOnMap() {
    this.dragendListener = google.maps.event.addListener(this.map, 'dragend', () => {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(this.searchFromMap.bind(this), 500)
    })

    this.zoomChangedListener = google.maps.event.addListener(this.map, 'zoom_changed', () => {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(this.searchFromMap.bind(this), 500)
    })
  }

  searchFromMap() {
    const searchBounds = this.map.getSearchBounds()
    const mapBounds = this.element.querySelector("#mapBounds")

    mapBounds.querySelector("#bounds_sw_lat").value = searchBounds.sw[0]
    mapBounds.querySelector("#bounds_sw_lng").value = searchBounds.sw[1]
    mapBounds.querySelector("#bounds_ne_lat").value = searchBounds.ne[0]
    mapBounds.querySelector("#bounds_ne_lng").value = searchBounds.ne[1]
    this.element.querySelector("#moved_map").value = true
    this.element.querySelector(".js-full-search-form").requestSubmit()
  }
}