import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    copyCopiedTranslation: { type: String, default: "Copied" },
    copyUnableTranslation: { type: String, default: "Unable to write into the clipboard" },
    newTranslation: String
  }

  copy() {
    this.writeToClipboard(this.textToCopy)
  }

  // Private

  writeToClipboard(text) {
    let data = new DataTransfer();
    data.items.add(text, "text/plain");
    navigator.clipboard.writeText(text).then(() => {
      if (this.newTranslationValue) {
        this.changeButtonAppearance();
      } else {
        alert(this.copyCopiedTranslationValue);
      }
    }, () => {
      alert(this.copyUnableTranslationValue)
    })
  }

  get textToCopy() {
    return this.data.get("text-to-copy")
  }

  changeButtonAppearance() {
    const originalText = this.element.innerHTML;
    const newButtonText = this.newTranslationValue;

    this.element.innerHTML = `<i class="fa-solid fa-circle-check"></i> <span>${newButtonText}</span>`;

    setTimeout(() => {
      this.element.innerHTML = originalText;
    }, 3000);
  }
}
