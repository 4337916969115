import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  renderStreamMessage(event) {
    fetch(event.target.dataset.url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    }).then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
