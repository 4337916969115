import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["priceTypeInput", "priceTypeHiddenInput", "priceLabel", "itemType"]
  static values = {
    perPerson: String
  }

  connect() {
    this.applyPricesSuffixes()
  }

  togglePerPerson(event) {
    this.applyPricesSuffixes()
  }

  applyPricesSuffixes() {
    if (this.selectedPriceType === "price_per_person") {
      this.priceLabelTargets.forEach(element => {
        if (!element.innerHTML.includes(this.perPersonValue)) {
          element.innerHTML = element.innerHTML + this.perPersonValue
        }
      })
    } else {
      this.priceLabelTargets.forEach(element => {
        element.innerHTML = element.innerHTML.replace(this.perPersonValue, '')
      })
    }
  }

  get selectedPriceType() {
    if(this.hasitemTypeTarget) {
      return this.itemTypeTarget.options[this.itemTypeTarget.selectedIndex].dataset.priceType
    }
  }
}
