import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload-pdf-box"
export default class extends Controller {
  static targets = ["field", "uploadResultNameFile", "submitButton", "downloadBox", "fileCard"]

  initUploadBox(e) {
    e.preventDefault();
    this.fieldTarget.click();
  }

  validUploadBox() {
    if (this.hasFileCardTarget && this.hasDownloadBoxTarget) {
      this.fileCardTarget.classList.remove("d-none")
      this.fileCardTarget.classList.add("d-flex")

      this.downloadBoxTarget.classList.add("d-none")

      if (this.hasSubmitButtonTarget) this.submitButtonTarget.disabled = false
    }

    this.uploadResultNameFileTarget.innerHTML = this.fieldTarget.files[0].name
  }

  resetInput() {
    this.fileCardTarget.classList.add("d-none")
    this.fileCardTarget.classList.remove("d-flex")

    this.downloadBoxTarget.classList.remove("d-none")

    if (this.hasSubmitButtonTarget) this.submitButtonTarget.disabled = true
  }
}
