import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  static targets = ["addButton"]

  connect() {
    super.connect()
  }

  add(event) {
    super.add(event)
    this.addButtonTarget.classList.add("d-none")
  }

  remove(event) {
    super.remove(event)
    this.addButtonTarget.classList.remove("d-none")
  }
}
