import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ["notDisplay"]
  static values = { moreText: String, lessText: String, open: Boolean }

  connect () {
    this.open = false
  }

  toggle (event) {
    this.open === false ? this.show(event) : this.hide(event)
  }

  show (event) {
    this.open = true
    event.target.innerHTML = this.lessTextValue
    this.notDisplayTargets.forEach(el => el.classList.remove("d-none"))
  }

  hide (event) {
    this.open = false
    event.target.innerHTML = this.moreTextValue
    this.notDisplayTargets.forEach(el => el.classList.add("d-none"))
  }
}
