import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.element.classList.contains('c--venue-list-item--fadeout')) {
      // setTimout is required to allow the element to be rendered before
      // the opacity is set to 0
      setTimeout(() => this.hide(), 100);
    }
  }

  hide () {
    this.element.style.opacity = '0'
    this.element.addEventListener('transitionend', () => this.element.parentNode.remove());
  }
}
