import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "togglable" ]

  connect() {
    this.toggleDisplay()
  }

  toggleDisplay() {
    if (this.checkboxTargets.some(checkbox => checkbox.checked)) {
      this.togglableTargets.forEach(el => el.classList.remove("d-none"))
    } else {
      this.togglableTargets.forEach(el => el.classList.add("d-none"))
    }
  }
}
