import { Controller } from "@hotwired/stimulus"
import { numberToCurrency } from "../helpers/currency_helpers"

export default class extends Controller {
  static targets = ["quantity", "unitPrice", "taxCode", "totalExclTax", "totalInclTax"]
  static values = {
    compoundedTaxRates: Array,
    taxRates: Object
  }

  connect() {
    this.toggleTotalInputs()

    if (!this.compoundedTaxRatesValue.includes(this.taxCodeValue)) {
      this.calculateTotalPrices()
    }
  }

  calculateTotalPrices() {
    this.calculateTotalInclTax()
    this.calculateTotalExclTax()
  }

  // Private

  calculateTotalExclTax() {
    let price = null

    if (this.unitPriceFormat === "ht") {
      if (!this.quantityValue || !this.unitPriceValue) {
        return this.totalExclTaxTarget.value = ""
      } else {
        price = this.quantityValue * this.unitPriceValue
      }
    } else {
      if (!this.quantityValue || !this.unitPriceValue || [null].concat(this.compoundedTaxRatesValue).includes(this.taxCodeValue)) {
        return this.totalExclTaxTarget.value = ""
      } else {
        price = (this.quantityValue * this.unitPriceValue / (1 + this.taxRate))
      }
    }

    this.totalExclTaxTarget.value = numberToCurrency(price)
  }

  calculateTotalInclTax() {
    let price = null

    if (this.unitPriceFormat === "ht") {
      if (!this.quantityValue || !this.unitPriceValue || [null].concat(this.compoundedTaxRatesValue).includes(this.taxCodeValue)) {
        return this.totalInclTaxTarget.value = ""
      } else {
        price = this.quantityValue * this.unitPriceValue * (1 + this.taxRate)
      }
    } else {
      if (!this.quantityValue || !this.unitPriceValue) {
        return this.totalInclTaxTarget.value = ""
      } else {
        price = this.quantityValue * this.unitPriceValue
      }
    }

    this.totalInclTaxTarget.value = numberToCurrency(price)
  }

  toggleTotalInputs() {
    this.enableAllTotalInputs()
    this.disableFileteredTotalInputs()
  }

  enableAllTotalInputs() {
    [this.totalExclTaxTarget, this.totalInclTaxTarget].forEach(input => input.disabled = false)
  }

  disableFileteredTotalInputs() {
    this.totalInputsToDisable.forEach(input => input.disabled = true)
  }

  get totalInputsToDisable() {
    if (this.compoundedTaxRatesValue.includes(this.taxCodeValue)) {
      if (this.unitPriceFormat === "ht") {
        return [this.totalExclTaxTarget]
      } else {
        return [this.totalInclTaxTarget]
      }
    } else {
      return [this.totalExclTaxTarget, this.totalInclTaxTarget]
    }
  }

  get quantityValue() {
    return parseFloat(this.quantityTarget.value) || 0
  }

  get unitPriceValue() {
    return parseFloat(this.unitPriceTarget.value) || 0
  }

  get taxCodeValue() {
    return this.taxCodeTarget.value || null
  }

  get taxRate() {
    return +this.taxRatesValue[this.taxCodeValue]
  }

  get unitPriceFormat() {
    return this.data.get("unitPriceFormat")
  }
}
