import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload"
export default class extends Controller {
  initialize() {
    this.initTosUpload()
  }

  initTosUpload() {
    const typesRegex = /[\/.](pdf)$/i
    const errorText = "Votre fichier doit être au format pdf et ne doit pas dépasser 10MB"
    new directUploaderWithProgressBar($(".js-document"),
                                      $("#tos_document"),
                                      ".js-add-document",
                                      ".js-progress-bar",
                                      ".js-title",
                                      ".delete-icon",
                                      typesRegex,
                                      10,
                                      errorText,
                                      this.setDocumentFields)
  }

  setDocumentFields(form, url, filename) {
    form.find("#billing_profile_tos_document_url").val(url)
    form.find("#billing_profile_tos_document_filename").val(filename)
  }
}
