import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fromHour", "toHour"]

  connect() {
    this.initTimepicker(this.fromHourTarget, this.targetOptions())

    if (this.hasToHourTarget) {
      this.initTimepicker(this.toHourTarget, this.targetOptions())
    }
  }

  initTimepicker(target, options) {
    $(target).timepicker(options)
  }

  targetOptions() {
    return { timeFormat: "H:i", minTime: "00:00", maxTime: "23:30"}
  }
}
