import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['phone']
  static values = {
    initialCountry: String,
    autoPlaceholder: String
  }

  connect() {
    this.phoneTargets.forEach((target) => {
      $(target).intlTelInput({
        formatOnInit: true,
        hiddenInput: target.name,
        initialCountry: this.initialCountry,
        preferredCountries: ["fr", "gb", "be", "es", "de", "it", "lu", "us", "ie"],
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.4.0/js/utils.js",
        autoPlaceholder: this.autoPlaceholder
      })

      // intlTelInput hides the error message, so we need to show it again
      const phoneInputWrapper = document.querySelector('.tel')
      if (phoneInputWrapper) {
        const phoneInputErrorFeedback = phoneInputWrapper.querySelector('.invalid-feedback')
        if (phoneInputErrorFeedback) {
          phoneInputErrorFeedback.classList.add('d-block')
        }
      }
    })
  }

  get initialCountry() {
    return this.initialCountryValue || "fr"
  }

  get autoPlaceholder() {
    return this.autoPlaceholderValue || "polite"
  }
}
