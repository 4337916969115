import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    status: Boolean,
    enable: String,
    disable: String,
    enabled: String,
    disabled: String
  }
  static targets = ["buttonText", "currentStatus"]

  connect() {
    const controller = this
    $(this.element).on("ajax:success", (event, xhr, status, error) => {
      $(this).form_did_save()
      controller.statusValue = !controller.statusValue
    })
  }

  statusValueChanged() {
    this._apply()
  }

  _apply() {
    if (this.statusValue) {
      this.element.method = "delete"
      this.buttonTextTarget.value = this.disableValue
      this.currentStatusTarget.innerHTML = this.enabledValue
    } else {
      this.element.method = "post"
      this.buttonTextTarget.value = this.enableValue
      this.currentStatusTarget.innerHTML = this.disabledValue
    }
  }
}
