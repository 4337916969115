import SimpleAutocomplete from "../helpers/simple_autocomplete.js"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select2", "form", "algoliaInstantSearch"]

  connect() {
    this.goToBooking()
    this.initAlgoliaFilter()
    $(this.select2Target).on("change", () => this.submit())
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  initAlgoliaFilter() {
    const element = this.algoliaInstantSearchTarget
    const indexName = app.algolia.getIndexName("Inquiry")
    const query = "type:Booking"
    const params = "bookings"
    const searchableAttributes = ["ID", "devis", "client", "lieu", "activité"]
    const url = "/admin/bookings/:id"
    const key = "id"

    new SimpleAutocomplete(element, indexName, query, params, false, searchableAttributes, url, key)
  }

  goToBooking() {
    document.querySelectorAll("td").forEach(td => {
      td.addEventListener("click", (e) => {
        if (!e.target.classList.contains("booking_status")) {
          const bookingId = e.target.closest("tr").dataset.bookingId
          window.open(`bookings/${bookingId}`, "_blank")
        }
      })
    })
  }
}
