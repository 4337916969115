export default class SimpleAutocomplete {
  constructor(
    input,
    index,
    facets,
    template,
    stackedView,
    hint,
    path,
    id
  ) {
    const handler = (element) => {
      if (input.value.length === 0 || element.type === 'focusout') {
        input.style.borderRadius = '5px';
      } else {
        input.style.borderRadius = '5px 5px 0 0';
      }
    };

    input.addEventListener('keyup', handler);
    input.addEventListener('focusout', handler);

    $(input).autocomplete({ hint: false }, [{
      source: (query, callback) => {
        client.initIndex(index).search(query,
          {facetFilters: [[facets]]})
          .then((answer) => {
            callback(answer.hits);
          }).catch(() => {
            callback([]);
          });
      },
      cssClasses: {
        suggestion: `suggestion ${stackedView ? 'aa-suggestion-stacked' : ''}`
      },
      templates: {
        empty: (context) => {
          return `
            <span>
              No result! Search: ${hint}.
            </span>
          `;
        },
        suggestion: (suggestion) => {
          return app.templates.autocomplete[template](suggestion);
        }
      }
    }]).on('autocomplete:selected', (event, suggestion, dataset) => {
      window.open(path.replace(':id', suggestion[id]), '_blank');
    });
  }
}
