import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    loaderText: String,
    url: String
  }

  disconnect() {
    $("#venue_details_modal").modal('hide')
  }

  click(e) {
    var showModal;
    showModal = () => {
      $.ajax({
        url: this.urlValue,
        type: 'get'
      });
      $("#venue_header").empty();
      $("#venue_header").append($(`<span>&nbsp;${this.loaderTextValue}</span>`));
      $("#venue_details").empty();
      $("#venue_details").append($("<i></i>", {
        "class": "fas fa-spinner fa-spin"
      }));
      $("#venue_details_modal").modal();
    };

    e.preventDefault();
    showModal();
    e.stopPropagation();
  }
}
