// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import "../public/polyfills"
import "@stimulus/polyfills"
import {Application} from "@hotwired/stimulus"
import {registerControllers} from 'stimulus-vite-helpers'

// External Libraries Controllers
import TextareaAutogrow from "stimulus-textarea-autogrow"

const application = Application.start()
const controllers = import.meta.globEager('./*_controller.js')
registerControllers(application, controllers)

application.register("textarea-autogrow", TextareaAutogrow)

// load viewComponents stimulus controllers
const viewComponentsControllers = import.meta.globEager('./../../../app/components/**/controller.js')

for (let path in viewComponentsControllers) {
	let module = viewComponentsControllers[path]

  const controllerName = path.match(/..\/components\/(.+)\/controller\.js$/)[1]
  if (typeof controllerName == "string") {
    let name = controllerName.replaceAll("_", "-")
                             .replaceAll("/", "--")
    application.register(name, module.default)
  }
}
