import { Controller } from "@hotwired/stimulus"
import {useDebounce} from "stimulus-use"

const MEDIUM_BREAKPOINT = 992

const MAX_ZOOM_BY_QUERY_TYPE = {
  "tourist_attraction": 15,
  "point_of_interest": 15,
  "establishment": 15,
  "premise": 15,
  "route": 15,
  "street_address": 15,
  "sublocality": 14,
  "neighborhood": 14,
  "locality": 13,
  "postal_code": 13,
  "administrative_area_level_2": 10,
  "administrative_area_level_1": 9,
  "country": 7
}

const PIN_COLORS = {
  "default": "#221644",
  "pinned": "#AE92FF",
  "hover": "#FFD71C"
}

export default class extends Controller {
  static debounces = ["submitForm"]
  static outlets = ["navbar-search-form", "venue-search-filter-bar"]
  static targets = [
    "centerPoint",
    "form",
    "map",
    "venueInfoWindow",
    "venueList",
    "venueListItem",
    "results",
    "queryInfo",
    "noResultsMessage",
    "modalBody",
    "mobileFilterButton",
    "packagesPopoverButton"
  ]

  async connect() {
    useDebounce(this, { wait: 500 })

    this.submitForm()

    // Load the Google Maps API and initialize the map
    await google.maps.importLibrary("maps");
    this.markers = []
    this.queryInfoMarker = null
    this.initMap()

    // Initialize search tracking
    this.trackedVenueList = []
    this.trackedMapMarkerPinsList = []
    this.hasTrackedMapMoved = false

    this.fireTurboRequestOnPopState()
  }

  // Create map marker & infoWindow when the venueInfoWindow is added to the DOM
  async venueInfoWindowTargetConnected(target) {
    const { Marker } = await google.maps.importLibrary("marker");
    this.addMarker(target)

    // Once all the venueInfoWindows are added to the DOM, fit the map bounds
    if (this.markers.length == this.venueInfoWindowTargets.length) {
      this.fitBounds()

      // if an infoWindow is already open and its venue still present, update its content
      // else close the infoWindow
      if (this.infoBox) {
        const infoBoxNewMarker = this.markers.find((marker) => marker[0].position.lat == this.infoBox.position_.lat && marker[0].position.lng == this.infoBox.position_.lng)

        if (infoBoxNewMarker) {
          const venueInfoWindow = infoBoxNewMarker[1]
          this.infoBox.setContent(venueInfoWindow.children[0].cloneNode(true))
        } else {
          this.infoBox.close()
          this.infoBox = null
        }
      }
    }
  }

  // Remove map marker when the venueInfoWindow is removed from the DOM
  async venueInfoWindowTargetDisconnected(target) {
    const { Marker } = await google.maps.importLibrary("marker");
    const markerContainer = this.markers.find((e) => e[1] == target)
    const marker = markerContainer[0]
    const index = this.markers.indexOf(markerContainer)
    marker.setMap(null)
    this.markers.splice(index, 1)
  }

  // Add event listeners to the search form inputs
  // When the user changes the search form inputs, push the form parameters to the URL and submit the form
  async formTargetConnected(target) {
    Array.from(target.elements).forEach(input => {
      input.addEventListener("change", () => {
        this.pushFormParamsToUrl()
        this.submitForm()
      })
    })
  }

  async noResultsMessageTargetConnected(target) {
    this.fitBounds()
  }

  async resultsTargetConnected(target) {
    this.scrollResultsToTop()
    this.trackVisibleVenues()
  }

  queryInfoTargetConnected() {
    this.setQueryInfoMarker()
  }

  queryInfoTargetDisconnected(target) {
    if (target.dataset.showMarker == "false") return

    if (this.queryInfoMarker) {
      const index = this.markers.indexOf(this.queryInfoMarker)
      this.queryInfoMarker.setMap(null)
      this.markers.splice(index, 1)
    }
  }

  // Initialize the map
  async initMap() {
    const centerPoint = new google.maps.LatLng(this.centerPointTarget.dataset.lat, this.centerPointTarget.dataset.lng)
    var zoom = parseInt(this.formTarget.elements.zoom.value) || 7
    this.map = new google.maps.Map(this.mapTarget, this.mapOptions(centerPoint, zoom))
    this.addMapListeners()
  }

  async setQueryInfoMarker() {
    if (this.queryInfoTarget.dataset.showMarker == "false") return

    const { Marker } = await google.maps.importLibrary("marker")
    this.queryInfoMarker = new google.maps.marker.AdvancedMarkerElement({
      map: this.map,
      position: {
        lat: parseFloat(this.queryInfoTarget.dataset.lat),
        lng: parseFloat(this.queryInfoTarget.dataset.lng)
      },
      content: this.purpleMarkerIcon()
    });

    this.markers.push(this.queryInfoMarker)
  }

  // Add event listeners to the map
  // dragend: when the map is dragged by the user, set the moved_map input to true
  // zoom_changed: when the map is zoomed by the user, set the moved_map input to true
  // idle: when the map stops moving, update the search form with the new bounds of the map and submit the form
  // mapMovedByUser: boolean to prevent the search form from submitting twice
  // boundsFitted: permit to ignore listeners when the map bounds are fitted
  addMapListeners() {
    this.mapMovedByUser = false
    this.boundsFitted = false

    google.maps.event.addListener(this.map, 'click', () => { this.infoBox?.close()})

    google.maps.event.addListener(this.map, 'dragend', () => {
      this.formTarget.elements.moved_map.value = true
      this.mapMovedByUser = true
    })

    google.maps.event.addListener(this.map, 'zoom_changed', () => {
      this.formTarget.elements.zoom.value = this.map.getZoom()
      if (this.boundsFitted) return

      this.formTarget.elements.moved_map.value = true
      this.mapMovedByUser = true
    })

    google.maps.event.addListener(this.map, 'idle', () => {
      if (this.mapMovedByUser && !this.boundsFitted) {
        this.resetFormElementAddress()
        this.searchFromMap()
        this.mapMovedByUser = false

        if (!this.hasTrackedMapMoved) {
          analytics.track('Venue Search Map Moved')
          this.hasTrackedMapMoved = true
        }
      } else {
        this.boundsFitted = false
      }
    })
  }

  // Frame the map around the venue markers on the page
  async fitBounds() {
    if (this.formTarget.elements.moved_map.value == "true") return

    const query = this.formTarget.elements.query.value
    const googlePlaceType = this.formTarget.elements.google_place_type.value
    const maxZoomOnFitBounds = query && googlePlaceType ? MAX_ZOOM_BY_QUERY_TYPE[googlePlaceType] : 9

    this.bounds = new google.maps.LatLngBounds();
    this.venueInfoWindowTargets.forEach((venueInfoWindow) => {
      this.bounds.extend({ lat: parseFloat(venueInfoWindow.dataset.lat), lng: parseFloat(venueInfoWindow.dataset.lng) })
    })

    if (this.hasQueryInfoTarget) {
      this.bounds.extend({ lat: parseFloat(this.queryInfoTarget.dataset.lat), lng: parseFloat(this.queryInfoTarget.dataset.lng) })
    }

    this.boundsFitted = true
    this.map.fitBounds(this.bounds)
    this.map.setZoom(Math.min(this.map.getZoom(), maxZoomOnFitBounds))
  }

  scrollResultsToTop() {
    this.venueListTarget.scrollTo({ top: 0 }); // Desktop view
    window.scrollTo({ top: 0 }); // Mobile view
  }

  // Create a marker on the map for a venueInfoWindow.
  // Adds an infoWindow to the marker that displays the venueInfoWindow.
  // Changes the color of the marker when the venueInfoWindow or the marker itself is hovered.
  addMarker(venueInfoWindow) {
    const pinned = venueInfoWindow.dataset.pinned == "true"
    const marker = new google.maps.marker.AdvancedMarkerElement({
      map: this.map,
      position: { lat: parseFloat(venueInfoWindow.dataset.lat), lng: parseFloat(venueInfoWindow.dataset.lng) },
      content: this.blueberryMarkerIcon(),
      zIndex: pinned ? 2 : 1
    });

    if (pinned) {
      marker.content.children[0].style.fill = "#AE92FF"
    }

    this.addInfoWindow(marker, venueInfoWindow)
    this.markers.push([marker, venueInfoWindow])

    marker.content.addEventListener('mouseenter', function() {
      marker.content.children[0].style.fill = PIN_COLORS["hover"]
    });

    marker.content.addEventListener('mouseleave', function() {
      marker.content.children[0].style.fill = PIN_COLORS[pinned ? "pinned" : "default"]
    });

    venueInfoWindow.previousElementSibling.addEventListener("mouseover", function() {
      marker.content.children[0].style.fill = PIN_COLORS["hover"]
    })

    venueInfoWindow.previousElementSibling.addEventListener("mouseout", function() {
      marker.content.children[0].style.fill = PIN_COLORS[pinned ? "pinned" : "default"]
    })
  }

  // Add an infoWindow to a marker that displays a venueInfoWindow
  // Close the infoWindow if another marker is clicked
  addInfoWindow(marker, venueInfoWindow) {
    marker.addListener("click", () => {
      if (this.trackedMapMarkerPinsList.indexOf(venueInfoWindow.dataset.venueId) == -1) {
        analytics.track('Venue Search Map Pin', { venue_id: venueInfoWindow.dataset.venueId})
        this.trackedMapMarkerPinsList.push(venueInfoWindow.dataset.venueId)
      }
    })

    marker.addListener("click", () => {
      // Close the infoWindow if the clicked marker is the same as the previous one
      if (this.infoBox?.position_ == marker.position) {
        this.infoBox?.close()
        this.infoBox = null
      } else {
        const infoWindowContent = venueInfoWindow.children[0].cloneNode(true)

        // Close the previous infoWindow if it exists
        if (this.infoBox) this.infoBox?.close();

        // Reuse the infoWindow of the clicked marker if exists
        // Else create a new infoWindow
        if (marker.infoBox) {
          this.infoBox = marker.infoBox
          this.infoBox.open(this.map, this.marker)
        } else {
          this.infoBox = new InfoBox({ alignBottom: true,
                                      pixelOffset: new (google.maps.Size)(-320 / 2, -40),
                                      infoBoxClearance: new (google.maps.Size)(10, 50),
                                      visible: true,
                                      closeBoxURL: '',
                                      zIndex: 10000 })
          this.infoBox.setContent(infoWindowContent)
          this.infoBox.setPosition(marker.position)
          this.infoBox.open(this.map, this.marker)
          marker.infoBox = this.infoBox
        }

        // Scroll the venue list to the corresponding venue
        // Highlight the venue in the list with a different color
        this.venueListItemTargets.forEach((e) => e.children[0].classList.remove("pinned"))
        venueInfoWindow.previousElementSibling.children[0].classList.add("pinned")
        venueInfoWindow.previousElementSibling.scrollIntoView({behavior: "smooth", block: "center"})
      }
    });
  }

  // Reset the address elements tracking when the map is moved
  resetFormElementAddress() {
    this.formTarget.elements.query.value = null
    this.formTarget.elements.address_establishment.value = null
    this.formTarget.elements.address_locality.value = null
    this.formTarget.elements.address_administrative_area_level_1.value = null
    this.formTarget.elements.address_administrative_area_level_2.value = null
    this.formTarget.elements.address_country.value = null
    this.formTarget.elements.address_zipcode.value = null
  }

  // Update the search form with the new bounds of the map and submit the form
  searchFromMap() {
    this.formTarget.elements.bounds_sw_lat.value = this.map.getBounds().getSouthWest().lat()
    this.formTarget.elements.bounds_sw_lng.value = this.map.getBounds().getSouthWest().lng()
    this.formTarget.elements.bounds_ne_lat.value = this.map.getBounds().getNorthEast().lat()
    this.formTarget.elements.bounds_ne_lng.value = this.map.getBounds().getNorthEast().lng()

    this.pushFormParamsToUrl()

    // prevents the form from submitting twice when fitBounds is called
    if (this.boundsFitted) {
      this.boundsFitted = false
    } else {
      this.submitForm()
    }
  }

  // Push the form parameters to the URL
  pushFormParamsToUrl() {
    const url = new URL(location);
    url.search = ""
    Array.from(this.formTarget.elements).filter((e) => e.type != "submit").forEach((e) => {
      const isRadioCheckbox = ["checkbox", "radio"].includes(e.type)
      const isValidRadioCheckbox = isRadioCheckbox && e.checked
      const isIgnoredHidden = e.type == "hidden" && e.value == "0" && !e.checked
      const shouldAppendParam = !isIgnoredHidden && (isValidRadioCheckbox || !isRadioCheckbox) && !!e.value
      const orderingByPrice = e.name == "order_by" && ["price_asc", "price_desc"].includes(e.value)

      // remove the order_by param if the user is ordering by price and the package filter is not selected
      if (orderingByPrice && !this.formTarget.elements.package.value) e.value = null

      if (shouldAppendParam) url.searchParams.append(e.name, e.value)
    })
    history.pushState({}, "", url);
  }

  submitForm() {
    this.formTarget.requestSubmit()
  }

  changeOrderBy(e) {
    let orderBy = e.target.value
    this.formTarget.elements.order_by.value = orderBy
    this.pushFormParamsToUrl()
    this.submitForm()
  }

  // Ensure the modal body scrolls to the top when the modal is opened
  scrollModalBodyToTop() {
    setTimeout(() => { this.modalBodyTarget.scrollTo({ top: 0 }) }, 200);
  }

  togglePackageFilter(e) {
    if (window.innerWidth < MEDIUM_BREAKPOINT) {
      this.mobileFilterButtonTarget.click()
    } else {
      this.packagesPopoverButtonTarget.click()
    }
    // track("Clicked on Page", { name: "Display total price"  })
  }

  // This allows to use the previous and next buttons of the browser.
  // The turbo request over a location.reload() permits to keep the map state.
  fireTurboRequestOnPopState() {
    window.addEventListener("popstate", function () {
      fetch(location.href, {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
        }
      })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
    })
  }

  // Custom marker icon
  blueberryMarkerIcon() {
    const parser = new DOMParser();

    const blueberryMarkerString = '<svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    `<path d="M25.5 13C25.5 14.6619 24.7409 16.6536 23.5354 18.7483C22.3372 20.8303 20.7328 22.9556 19.1178 24.87C17.5044 26.7825 15.8896 28.4738 14.6775 29.6876C14.0717 30.2942 13.5671 30.7808 13.2145 31.1153C13.1351 31.1907 13.0634 31.2583 13 31.3178C12.9366 31.2583 12.8649 31.1907 12.7855 31.1153C12.4329 30.7808 11.9283 30.2942 11.3225 29.6876C10.1104 28.4738 8.49555 26.7825 6.88217 24.87C5.26719 22.9556 3.66278 20.8303 2.46461 18.7483C1.25914 16.6536 0.5 14.6619 0.5 13C0.5 6.09644 6.09644 0.5 13 0.5C19.9036 0.5 25.5 6.09644 25.5 13Z" fill="${PIN_COLORS["default"]}" stroke="white"/>` +
    '<circle cx="13" cy="13" r="5" fill="white"/>' +
    '</svg>'

    return parser.parseFromString(blueberryMarkerString, "image/svg+xml").documentElement
  }

  purpleMarkerIcon() {
    const parser = new DOMParser();

    const purpleMarkerString = '<svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    `<path d="M25.5 13C25.5 14.6619 24.7409 16.6536 23.5354 18.7483C22.3372 20.8303 20.7328 22.9556 19.1178 24.87C17.5044 26.7825 15.8896 28.4738 14.6775 29.6876C14.0717 30.2942 13.5671 30.7808 13.2145 31.1153C13.1351 31.1907 13.0634 31.2583 13 31.3178C12.9366 31.2583 12.8649 31.1907 12.7855 31.1153C12.4329 30.7808 11.9283 30.2942 11.3225 29.6876C10.1104 28.4738 8.49555 26.7825 6.88217 24.87C5.26719 22.9556 3.66278 20.8303 2.46461 18.7483C1.25914 16.6536 0.5 14.6619 0.5 13C0.5 6.09644 6.09644 0.5 13 0.5C19.9036 0.5 25.5 6.09644 25.5 13Z" fill="${PIN_COLORS["pinned"]}" stroke="white"/>` +
    '<circle cx="13" cy="13" r="5" fill="white"/>' +
    '</svg>'

    return parser.parseFromString(purpleMarkerString, "image/svg+xml").documentElement
  }

  // Configure the map options
  mapOptions(centerPoint, zoom = 7) {
    const optionHash = {
      center: centerPoint,
      zoom: zoom,
      mapId: '3fe2568bfd28a7e1',
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      }
    }

    return optionHash
  }


  // Tracking Methods
  trackVisibleVenues() {
    const nbVenuesPerPage = 24;
    this.venueListItemTargets.forEach((card) => {
      const { venueId, category, position, page } = card.children[0].dataset
      if(this.isScrolledIntoView && this.isScrolledIntoView(card) && this.trackedVenueList.indexOf(venueId) == -1) {
        const totalPosition = (page - 1) * nbVenuesPerPage + (Number(position) + 1)
        const properties = { venue_id: venueId, category, position: totalPosition, page, source: "listing"}
        analytics.track('Viewed Search Result', properties)
        this.trackedVenueList.push(venueId)
      }
    })
  }

  isScrolledIntoView(el) {
    const rectElement = el.getBoundingClientRect();
    return (rectElement.top >= 0) && (rectElement.bottom <= window.innerHeight);
  }
}
