import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["blockToDisplay"]

  connect() {
    this.toggleElements()
    this.toggleRadioElements()
    this.toggleCheckboxElements()
  }

  display() {
    this.toggleElements()
  }

  toggleElements() {
    this.blockToDisplayTargets.forEach((elt) => {
      if (!elt.dataset.displayIf) return

      let displayConditions = JSON.parse(elt.dataset.displayIf)
      let conditions = this.conditionIterator(displayConditions)
      let visibilityStatus = this.visibilityStatusChecker(elt, conditions)
      elt.classList.toggle("d-none", !visibilityStatus)
    });
  }

  toggleRadioElements(event) {
    if (event) {
      this.blockToDisplayTargets.forEach((elt) => { if (!elt.classList.contains("d-none")) { elt.classList.toggle("d-none") }})
      const selector = `input[name="${event.target.dataset.inputName}"]:checked`
      let triggerElt = document.querySelector(selector)?.value
      if (triggerElt) {
        document.getElementById(triggerElt)?.classList.toggle("d-none")
      }
    }
  }

  toggleCheckboxElements() {
    // TODO Benoit: Replace this behaviour by the new logic on l.57 to 60
    this.blockToDisplayTargets.forEach((elt) => {
      if (!elt.dataset.displayIfChecked) return
      const input = this.element.querySelector("#" + elt.dataset.displayIfChecked)
      let visibilityStatus = input?.checked
      elt.classList.toggle("d-none", !visibilityStatus)
    });
  }

  // private

  conditionIterator(displayConditions) {
    let conditionArray = []

    for (const [id, condition] of Object.entries(displayConditions)) {
      const input = this.element.querySelector("#" + id)

      if (condition === "checkbox_checked") {
        conditionArray.push(this.handleCheckboxChecked(input))
        continue
      }
      
      switch (typeof condition) {
        case "boolean":
          conditionArray.push(this.handleBooleanCondition(condition, input))
          continue
        case "number":
        case "string":
          conditionArray.push(this.handleStringOrNumberCondition(condition, input))
          continue
        case "object":
          conditionArray.push(this.handleObjectCondition(condition, input))
          continue
        default:
          continue
      }
    }
    return conditionArray
  }

  handleBooleanCondition(condition, input) {
    return condition === true && input?.value
  }

  handleCheckboxChecked(input) {
    return input?.checked
  }

  handleStringOrNumberCondition(condition, input) {
    return condition.toString() === input?.value
  }

  handleObjectCondition(condition, input) {
    return condition.includes(input?.value)
  }
  
  visibilityStatusChecker(elt, conditions) {
    if (elt.dataset.conditionOperator && elt.dataset.conditionOperator === "OR") {
      return conditions.some(Boolean)
    } else {
      return conditions.every(Boolean)
    }
  }
}
