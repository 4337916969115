import Trix from 'trix'

Trix.config.textAttributes.underline = {
  style: { "textDecoration": "underline" },
  inheritable: true,
  parser: e => e.style.textDecoration == "underline"
}

const RICH_TEXT_EDITOR_COLORS = [
  "rgb(0, 0, 0)",
  "rgb(240, 66, 69)",
  "rgb(255, 133, 42)",
  "rgb(255, 193, 7)",
  "rgb(72, 203, 75)",
  "rgb(32, 201, 151)",
  "rgb(0, 123, 255)",
  "rgb(102, 16, 242)",
  "rgb(232, 62, 140)"
]

Trix.config.textAttributes.foregroundColor = {
  styleProperty: "color",
  inheritable: true,
  parser: (element) => {
    // As long as the current element is not the root editor
    while (element && element.tagName !== "TRIX-EDITOR") {
      if (element.style["color"]) {
        if (RICH_TEXT_EDITOR_COLORS.indexOf(element.style["color"]) !== -1) {
          // If the color attribute is set on the element and it is a color
          // from the predefined set, we return the color.
          // It will then be added in the dom by the parser, using the `styleProperty`.
          return element.style["color"] // breaks the while loop
        }
      }
      // The color of a node can be set by a parent node,
      // we move up the dom hierarchy to find if a parent sets the color
      element = element.parentElement
    }
  }
}
