import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.invalidFields.length > 0) {
      this.invalidFields[0].scrollIntoView()
    }
  }

  get invalidFields() {
    return document.querySelectorAll(".form-group-invalid")
  }

  disableSubmitOnEnter(e) {
    const keyCode = e.keyCode || e.which
    const enterKeyCode = 13
    if (keyCode === enterKeyCode) {
      e.preventDefault()
    }
  }
}
