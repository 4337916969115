// This class represents a venue marker on the map and associated features:
// 1. Changing marker icon when hovering over it
// 2. Opening up an infobox with the venue card when clicking on it
class GoogleMapMarker {
  // Class variables shared among all instances of MapMarker
  static maxZIndex = 10000;
  static infobox = undefined;

  constructor(venueCard, map) {
    this.venueCard = venueCard;
    this.map = map;
    this.lat = this.venueCard.lat;
    this.lng = this.venueCard.lng;

    this.marker = new google.maps.Marker({
      position: new google.maps.LatLng(this.lat, this.lng),
      map: this.map,
      icon: google.maps.MarkerIcon
    });

    // Change icon when hovering over marker
    google.maps.event.addListener(this.marker, 'mouseover', () => this.changeMarkerIcon());
    google.maps.event.addListener(this.marker, 'mouseout', () => this.resetMarkerIcon());

    // Change icon when hovering on venueCard
    this.venueCard.element.addEventListener("mouseenter", () => this.changeMarkerIcon());
    this.venueCard.element.addEventListener("mouseleave", () => this.resetMarkerIcon());

    google.maps.event.addListener(this.marker, 'click', () => this.openVenueInfobox());
  }

  getPosition() {
    return this.marker.getPosition();
  }

  detachFromMap() {
    this.marker.setMap(null);
  }

  changeMarkerIcon() {
    MapMarker.maxZIndex++;
    this.marker.setZIndex(MapMarker.maxZIndex);
    this.marker.setIcon(google.maps.MarkerIconHover);
  }

  resetMarkerIcon() {
    this.marker.setIcon(google.maps.MarkerIcon);
  }

  openVenueInfobox() {
    if (MapMarker.infobox) MapMarker.infobox.close();

    MapMarker.infobox = new InfoBox(google.maps.Map.infoboxOptions);

    // Clone venue partial and reproduce it in infobox
    const infoboxContent = this.venueCard.element.parentElement.cloneNode(true);
    infoboxContent.classList.add("venue-infobox", "rounded");
    MapMarker.infobox.setContent(infoboxContent);
    MapMarker.infobox.setPosition(this.marker.position);
    MapMarker.infobox.open(this.map, this.marker);

    // Tracking
    const card = infoboxContent.querySelector(".venue-card");
    if (window.trackVisible) trackVisible(card, "map");
  }
}


export default GoogleMapMarker;
