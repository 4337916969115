import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["blockToToggle", "toggleAction"]

  toggle() {
    if (this.hasToggleActionTarget) {
      this.toggleActionTarget.classList.add("d-none")
    }
    this.blockToToggleTargets.forEach((element) => {
      element.classList.toggle("d-none");
    });
  }

  resetToggle() {
    if (this.hasToggleActionTarget) {
      this.toggleActionTarget.classList.remove("d-none")
    }
    this.blockToToggleTargets.forEach((element) => {
      element.classList.add("d-none");
    });
  }
}
