import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    $(this.inputTarget).typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    },
    {
      limit: 5,
      source: this.substringMatcher($(this.element).data("legends"))
    })
  }

  // Private

  substringMatcher(strings) {
    return function(q, cb) {
      let matches = [];
      let substrRegex = new RegExp(q, 'i');
      $.each(strings, function(_i, string) {
        if (substrRegex.test(string)) {
          matches.push(string);
        }
      });
      cb(matches);
    };
  };
}
