import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["actionToggle", "blockToToggle"]

  connect() {
    if (this.toggleOnConnect == "true") {
      this.open()
    }
  }

  toggleAttributes() {
    if (this.shouldOpen()) {
      this.open()
    } else {
      this.close()
    }
  }

  // Private

  shouldOpen() {
    if (this.data.has("reverse") && (this.data.get("reverse") == "true")) {
      return !this.actionToggleTarget.checked
    } else {
      return this.actionToggleTarget.checked
    }
  }

  open() {
    this.blockToToggleTarget.classList.remove("d-none")
  }

  close() {
    this.blockToToggleTarget.classList.add("d-none")
  }

  get toggleOnConnect() {
    return this.data.get("toggle-on-connect")
  }
}
