import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["countdown", "waiting"]

  connect() {
    this.startCountdown()
  }

  private

  startCountdown() {
    this.waitingTarget.classList.add("disabled")
    this.countdown = this.start

    this.counter = 0
    this.timer = setInterval(() => { this.updateCountdown() }, 1000);
  }

  stopCountdown() {
    this.waitingTarget.classList.remove("disabled")
    clearInterval(this.timer)
  }

  updateCountdown() {
    if (this.counter >= this.start) {
      this.stopCountdown()
    } else {
      this.counter++
      this.countdown = this.start - this.counter
    }
  }

  set countdown(value) {
    this.countdownTarget.innerHTML = value
  }

  get start() {
    return parseInt(this.data.get("start"))
  }
}
