import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    var filename = "";
    fetch(this.urlValue)
      .then((result) => {
        const parts = result.headers.get('Content-Disposition').split(';');
        filename = parts[1].split('=')[1].replaceAll("\"", "");
        return result.blob();
      })
      .then((blob) => {
        if (blob != null) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      });
  }
}
