import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textareaContainer"]
  static values = {
    warning: String
  }

  generate(e) {
    if(this.textareaContent == "")
      return;

    if(!window.confirm(this.warningValue)) {
      e.preventDefault()
    }
  }

  get textareaContent() {
    return this.textareaContainerTarget.querySelector("textarea").value
  }
}
