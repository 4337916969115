import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["amountInput", "difference", "confirmButton", "invalidValueMessage"]
  static values = { transactionAmount: String }

  updateDifference() {
    let paymentSum = this.calculatePaymentSum()
    let diff = this.calculateDiff(paymentSum)
    this.updateModalUI(diff)
  }

  calculatePaymentSum() {
    return this.amountInputTargets.reduce(
      (partialSum, elt) => partialSum + parseFloat(elt.value)
    , 0)
  }

  calculateDiff(paymentSum) {
    return parseFloat(this.transactionAmountValue) - paymentSum
  }

  invalidValues() {
    return this.amountInputTargets.some(input => {
      let value = parseFloat(input.value)
      let max = parseFloat(input.dataset.maxValue)
      return value <= 0 || value > max
    })
  }

  updateModalUI(diff) {
    this.differenceTarget.innerText = diff.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
    let parent = this.differenceTarget.parentElement
    if (parseFloat(diff.toFixed(2)) === 0.00 && !this.invalidValues()) {
      this.confirmButtonTarget.disabled = false
      parent.classList.remove("text-danger")
    } else {
      this.confirmButtonTarget.disabled = true
      parent.classList.add("text-danger")
    }

    if (this.invalidValues()) {
      this.invalidValueMessageTarget.classList.remove("d-none")
    } else {
      this.invalidValueMessageTarget.classList.add("d-none")
    }
  }
}
