import { Controller as BaseController } from "@hotwired/stimulus";

export default class extends BaseController {
  static targets = ["scrollable"]

  static values = { show: Boolean, scrollToAnchor: String, displayDelay: Number }

	connect() {
    if (this.showValue) setTimeout(() => $(this.element).modal("show"), this.displayDelayValue)
    if (this.scrollToAnchorValue) setTimeout(() => this.scrollElementIntoView(), 1000)
  }

  disconnect() {
    if (this.showValue) {
      $(this.element).modal("hide")
      $(".modal-backdrop").remove()
    }
  }

  scrollElementIntoView() {
    this.element.querySelector(`#${this.scrollToAnchorValue}`).scrollIntoView({ behavior: "smooth" })
  }

  setScrollable() {
    if (!this.scrollableTarget.classList.contains("d-none") || document.querySelector(".c--modal").classList.contains("c--modal-dialog-scrollable")) {
      document.querySelector(".c--modal").classList.toggle("c--modal-dialog-scrollable")
    }
  }
}
