import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { minimumChars: Number }

    search(e) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.url = this.element.getAttribute("action")
        let query = new URLSearchParams(new FormData(this.element)).toString()

        if (this.minimumCharsValue && e.target.value.length < this.minimumCharsValue) {
          query = ""
        }

        fetch(`${this.url}?${query}`, {
            method: "GET",
            headers: { Accept: "text/vnd.turbo-stream.html" }
        })
        .then(r => r.text())
        .then(html => window.Turbo.renderStreamMessage(html))
      }, 500)
    }
}
