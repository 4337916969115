import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.format()
  }

  format() {
    if (this.element.value.length > 0) {
      this.element.value = this.element.value.replace(/ /g, '').match(/.{1,4}/g).join(' ')
    }
  }
}
