import {Controller} from "@hotwired/stimulus"
import Swiper, {Lazy, Navigation} from "swiper"

export default class extends Controller {
  static targets = [  "carousel" ]

  connect() {
    this.swiper = new Swiper(this.carouselTarget, {
      modules: [Lazy, Navigation],
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      slidesPerView: 1,
      speed: 1,
      lazy: {
        loadPrevNext: true,
        checkInView: true
      },
      loop: true,
      preloadImages: false,
      simulateTouch: false
    })
  }
}
