import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  async openModal(event) {
    const response = await get(event.target.dataset.url)
    if (response.ok) {
      $("#resource_modal").html(await response.text)
      $("#resource_modal").find(".modal").modal("show")
    }
  }
}
