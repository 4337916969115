import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fromOption", "toOption"]

  connect() {
    this.setDurations()
  }

  setDurations() {
    if (this.hasFromOptionTarget && this.hasToOptionTarget) {
      document.querySelectorAll("#" + this.toOptionTarget.id + " option").forEach(opt => {
        opt.disabled = parseInt(opt.value) <= parseInt(this.fromOptionTarget.value);
      })

      document.querySelectorAll("#" + this.fromOptionTarget.id + " option").forEach(opt => {
        opt.disabled = parseInt(opt.value) >= parseInt(this.toOptionTarget.value);
      })
    }
  }
}
