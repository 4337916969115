import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "revenue", "totalAmount"];

  checkRevenue(event) {
    event.preventDefault();
    const revenue = parseFloat(this.revenueTarget.value.replace(',', '.'));
    const totalAmount = parseFloat(this.totalAmountTarget.value.replace(',', '.'));

    if (revenue >= 0.3 * totalAmount) {
      $("#revenue_confirmation_modal").modal('show')
    } else {
      this.formTarget.submit();
    }
  }

  confirm() {
    this.formTarget.submit();
  }
}
