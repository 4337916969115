import { Controller as BaseController } from "@hotwired/stimulus";

export default class extends BaseController {
  static targets = ["buttonLabel", "hiddenInput", "item"]

  connect() {
    if(this.hasHiddenInputTarget && this.hiddenInputTarget.value != "") {
      let matchedItem  = this.itemTargets.find((item) => item.dataset.value == this.hiddenInputTarget.value)
      if(matchedItem)
        this.setSelectedItem(matchedItem)
    } else {
      this.setSelectedItem(this.itemTargets[0])
    }
  }

  setSelectedItem(item) {
    this.buttonLabelTarget.innerHTML = item.dataset.selectedText

    if(this.hasHiddenInputTarget) {
      this.hiddenInputTarget.value = item.dataset.value
      this.hiddenInputTarget.dispatchEvent(new CustomEvent('change', { 'bubbles': true }))
    }
  }

  select(e) {
    let item = e.target
    this.setSelectedItem(item)
  }
}
