import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "item" ]

  setActive(event) {
    this.itemTargets.forEach((item) => {
      if (item == event.target || item.contains(event.target)) {
        item.classList.add("active")
        window.history.replaceState(window.history.state, '', item.dataset.path)
      } else {
        item.classList.remove("active")
      }
    })

  }
}
