import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option", "input", "submit"]

  toCamelCase(str) {
    return str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''))
  }

  selectRadioOption(event) {
    this.optionTargets.forEach((el, i) => {
      el.classList.toggle("active", event.target == el )
    })
    this.inputTarget.value = this.inputTarget.dataset[this.toCamelCase(event.target.value) + "Translation"]
    this.inputTarget.disabled = false
    this.submitTarget.disabled = false
  }
}
