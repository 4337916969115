import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "comment", "confirmButton" ]

  connect() {
    this.toggleButton();
  }

  toggleButton() {
    const isCommentPresent = this.commentTarget.value.trim() !== "";
    this.confirmButtonTarget.disabled = !isCommentPresent;
  }
}
