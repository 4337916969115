import { Controller as BaseController } from "@hotwired/stimulus";

export default class extends BaseController {
  static targets = ["item", "showMore"];

  showMore() {
    this.itemTargets.forEach((item) => {
      item.classList.remove("d-none");
      setTimeout(() => {
        item.classList.remove("visibility-hidden");
      }, 100);
    });

    this.showMoreTarget.classList.add("d-none");
  }
}
