import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  static values = { min: Number }
  static targets = ["trashButton"]

  connect() {
    super.connect()
    this.initialDisableButtons()
  }

  add(event) {
    super.add(event)
    this.disableButtons()
  }

  remove(event) {
    super.remove(event)
    this.disableButtons()
  }

  disableButtons() {
    const elements = $(`${this.wrapperSelectorValue}:visible`)
    if (elements.length == this.min) {
      this.trashButtonTargets.forEach((button) => { button.disabled = true })
    } else {
      this.trashButtonTargets.forEach((button) => { button.disabled = false })
    }
  }

  initialDisableButtons() {
    const elements = $(`${this.wrapperSelectorValue}`)
    if (elements.length <= this.min) {
      this.trashButtonTargets.forEach((button) => { button.disabled = true })
    }
  }

  get min() {
    return this.hasMinValue ? this.minValue : 0
  }
}
