import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["select"]
  static values = {
    url: String,
    param: String
  }

  change(event) {
    const form = event.target.form
    const formData = new FormData(form)

    let params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append("html_target", this.selectTarget.id)

    for (let entry of formData) {
      const key = entry[0]
      const value = entry[1]
      params.append(key, value)
    }

    get(`${this.urlValue}?${params}`, { responseKind: "turbo-stream"  })
  }
}
