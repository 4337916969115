import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["row"]

  connect() {
    this.setCursor()
  }

  // private
  setCursor(){
    this.rowTargets.forEach(row => {
      const hasLink = row.dataset.tableHeaderComponentUrlParam

      row.querySelectorAll("td").forEach(cell => {
        const isClickable = !cell.classList.contains("unclickable")
        if(hasLink && isClickable) {
          cell.style.cursor = "pointer"
        }
      })
    })
  }

  redirect(event){
    const isActionCell = event.target.closest("td").classList.contains("actions-cell")
    const isExcludeCell = event.target.closest("td").classList.contains("unclickable")
    const url = event.params.url

    if(url && !isActionCell && !isExcludeCell) {
      window.open(event.params.url, event.params.target)
    }
  }
}
