import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // Track Method Parameters with action params
  // data-tracking-should-track-param="boolean||undefined"
  // data-tracking-event-name-param="string"
  // data-tracking-properties-param="object.to_json"
  track(event) {
    if (event.params.shouldTrack !== false) {
      analytics.track(event.params.eventName, event.params.properties)
    }
  }
}
