// [Deprecated] Use app/components/modal/component.rb and the associated controller
// instead of layout modal + this controller
import { Controller } from "@hotwired/stimulus"
import "@hotwired/turbo-rails";

export default class extends Controller {
  static values = { scriptOnClose: String }

  connect() {
    const modal = $(this.element).find(".modal")

    modal.modal("show")

    if (this.scriptOnCloseValue) {
      modal.on('hide.bs.modal', () => {
        try {
          eval(this.scriptOnCloseValue)
        } catch (e) {
          console.log(e)
        }
      })
    }
  }

  disconnect() {
    $(this.element).find(".modal").modal("hide")
    $(".modal-backdrop").remove()
  }
}
