// We reserve Controller for the export name
import {Controller as BaseController} from "@hotwired/stimulus";

export default class extends BaseController {
  static targets = ["button"]

  connect() {
    setTimeout(() => this.endBounce(), 1000)
  }

  endBounce() {
    const icon = this.buttonTarget.querySelector(".fa-bounce")
    if (icon) {
      icon.classList.remove("fa-bounce")
    }
  }

  disposeTooltip() {
    $(this.buttonTarget).tooltip("dispose")
  }
}
