import { Controller } from "@hotwired/stimulus"

// Very DIY code due to the behavior of bootstrap-datepicker and jquery.timepicker,
// both of which agree not to trigger the `change` event on inputs,
// but which also handle blur completely differently.
//
// Sooner or later, the most lasting solution will be to replace these jQuery libraries
// with custom JS/CSS for our date/time pickers.
export default class extends Controller {
  static targets = ["dateStart", "fromHour", "dateEnd", "toHour"]

  connect() {
    jQuery('#event_from_date').datepicker().on('changeDate', function(ev) {
      input = jQuery('#event_from_hour')
      if (input.val() == "") {
        input.focus();
      }
    });

    jQuery('#event_to_date').datepicker().on('changeDate', function(ev) {
      input = jQuery('#event_to_hour')
      if (input.val() == "") {
        input.focus();
      }
    });
  }

  changeFocus(event) {
    if (event.target.id == "event_from_hour" && this.dateEndTarget.value == "") {
      setTimeout(() => this.dateEndTarget.focus(), 100);
    }
  }
}
