import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  download(event) {
    event.preventDefault()

    const originalText = this.element.textContent
    this.element.textContent = "Export en cours..."
    this.element.disabled = true

    const currentUrl = new URL(window.location.href)
    fetch(currentUrl, {
      headers: { "Accept": "text/csv" }
    }).then(response => {
      if (response.ok) return response.blob()

      throw new Error("L'export n'a pas fonctionné.")
    }).then(blob => {
      this.triggerDownload(blob)
      this.element.textContent = originalText
      this.element.disabled = false
      this.displayFlash()
    })
  }

  triggerDownload(blob) {
    const date = new Date().toISOString().slice(0, 10)
    const url = window.URL.createObjectURL(blob)
    const downloadLink = document.createElement("a")

    downloadLink.href = url
    downloadLink.setAttribute("download", `factures-${date}.csv`)
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  displayFlash() {
    const flash = document.querySelector("#flash-message")
    flash.classList.remove("d-none")
    flash.style.display = "block"
  }
}
