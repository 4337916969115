import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).on("change", () => {
      if (this.element.getAttribute("data-turbo") == "true") {
        this.url = $(this.element).val()
        fetch(this.url, {
          headers: {
            Accept: "text/vnd.turbo-stream.html"
          }
        })
        .then(r => r.text())
        .then(html => window.Turbo.renderStreamMessage(html))
      } else {
        window.location = $(this.element).val()
      }
    })
  }
}
