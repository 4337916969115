import { Controller } from "@hotwired/stimulus"

const GOOGLE_PLACE_TYPES = [
  "tourist_attraction",
  "point_of_interest",
  "establishment",
  "premise",
  "street",
  "route",
  "street_address",
  "locality",
  "postal_code",
  "sublocality",
  "neighborhood",
  "administrative_area_level_2",
  "administrative_area_level_1",
  "country",
]

const ADDRESS_TYPE_COMPONENTS = {
  establishment: "addressEstablishmentInput",
  locality: "addressLocalityInput",
  administrative_area_level_1: "addressAdministrativeAreaLevel1Input",
  administrative_area_level_2: "addressAdministrativeAreaLevel2Input",
  country: "addressCountryInput",
  postal_code: "addressZipCodeInput"
}

export default class extends Controller {
  static targets = [
    "query", "locationTypeButton", "packButton", "searchCategoryInput",
    "googlePlaceTypeInput", "googlePlaceNameInput", "googlePlaceIdInput", "googlePlaceLatInput", "googlePlaceLngInput",
    "addressEstablishmentInput", "addressLocalityInput", "addressAdministrativeAreaLevel1Input",
    "addressAdministrativeAreaLevel2Input", "addressCountryInput", "addressZipCodeInput", "searchCategoryTab"
  ]

  static values = {
    autocompleteCountries: Array,
    queryInputPlaceholder: Object,
    queryInputDropdownTitle: String
  }

  static outlets = ["form--google-autocomplete-input"]

  formGoogleAutocompleteInputOutletConnected(outlet, element) {
    outlet.selectGooglePlace = this.selectGooglePlace.bind(this)
    outlet.selectDefaultSuggestion = this.selectDefaultSuggestion.bind(this)
  }

  currentSearchCategory() {
    return Array.from(this.searchCategoryTabTarget.children).find(tab => tab.firstElementChild.classList.contains("active")).dataset.value
  }

  currentQuery() {
    return Array.from(this.queryTargets).find(input => input.dataset.categoryContext == this.currentSearchCategory())
  }

  currentSuggestionDropdown() {
    return Array.from(this.suggestionDropdownTargets).find(node => node.parentElement.parentElement.dataset.categoryContext == this.currentSearchCategory())
  }

  propagateQueryValue() {
    this.queryTargets.forEach(target => target.value = this.currentQuery().value)
  }

  updateLocationTypeButton(event) {
    this.locationTypeButtonTarget.textContent = event.target.dataset.labelText
  }

  updatePackButton(event) {
    const selectedTarget = this.packButtonTargets.find(target => target.dataset.searchCategory === event.target.dataset.searchCategory)
    selectedTarget.textContent = event.target.dataset.labelText
  }

  selectGooglePlace(placeData) {
    const formattedAddress = placeData.formatted_address || null
    const placeTypes = placeData.types || null

    Object.entries(ADDRESS_TYPE_COMPONENTS).forEach(([type, targetName]) => {
      this[targetName + "Targets"].forEach(target => {
        target.value = placeData.address_components?.find(component => component.types.includes(type))?.long_name || null
      })
    })

    if (placeTypes) {
      const isEstablishment = placeTypes?.includes("establishment")

      this.googlePlaceTypeInputTargets.forEach(target => target.value = placeTypes?.find(type => GOOGLE_PLACE_TYPES.includes(type)) || null)
      this.googlePlaceNameInputTargets.forEach(target => target.value = isEstablishment ? placeData.name : null)
      this.googlePlaceIdInputTargets.forEach(target => target.value = isEstablishment ? placeData.place_id : null)
      this.googlePlaceLatInputTargets.forEach(target => target.value = isEstablishment ? placeData.geometry.location.lat() : null)
      this.googlePlaceLngInputTargets.forEach(target => target.value = isEstablishment ? placeData.geometry.location.lng() : null)
    }

    if (this.currentQuery().value == "" || formattedAddress != null) {
      this.currentQuery().value = formattedAddress
      this.currentQuery().setAttribute("value", formattedAddress)
    }

    this.propagateQueryValue()
    this.currentQuery().blur()
  }

  selectDefaultSuggestion(formattedAddress) {
    this.googlePlaceTypeInputTargets.forEach(target => target.value = "locality")
    this.googlePlaceNameInputTargets.forEach(target => target.value = null)
    this.googlePlaceIdInputTargets.forEach(target => target.value = null)
    this.googlePlaceLatInputTargets.forEach(target => target.value = null)
    this.googlePlaceLngInputTargets.forEach(target => target.value = null)
    this.propagateQueryValue()
  }
}
