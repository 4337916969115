import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["amountHT", "amountTTC", "taxCode"]
  static values = {
    compoundedTaxRates: Array,
    taxRates: Object
  }

  calculTVA() {
    if (this.unit == "ht" && this.taxCodeValue && this.amountHTTValue) {
      if (this.compoundedTaxRatesValue.includes(this.taxCodeValue)) {
        this.amountTTCTarget.value = null
      } else {
        this.amountTTCTarget.value = this.ccomputeTTC(this.amountHTTValue, this.taxRate)
      }
    } else if (this.unit == "ttc" && this.taxCodeValue && this.amountTTCTValue) {
      if (this.compoundedTaxRatesValue.includes(this.taxCodeValue)) {
        this.amountHTTarget.value = null
      } else {
        this.amountHTTarget.value = this.computeHT(this.amountTTCTValue, this.taxRate)
      }
    }
  }

  ccomputeTTC(amountHT, taxRate) {
    return Math.round(amountHT * this.taxCoefficient(taxRate) * 100)/100
  }

  computeHT(amountTTC, taxRate) {
    return Math.round((amountTTC / this.taxCoefficient(taxRate)) * 100)/100
  }

  taxCoefficient(taxRate) {
    return 1 + taxRate
  }

  // Getters

  get unit() {
    return this.data.get("unit")
  }

  get taxCodeValue() {
    return this.taxCodeTarget.value || null
  }

  get amountHTTValue() {
    return this.amountHTTarget.value || null
  }

  get amountTTCTValue() {
    return this.amountTTCTarget.value || null
  }

  get taxRate() {
    return +this.taxRatesValue[this.taxCodeValue]
  }
}
