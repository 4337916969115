import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "hidable"]
  static values = { showIf: String }

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.selectTarget.value != this.showIfValue) {
      this.hidableTarget.hidden = true
    } else if (this.selectTarget.value = this.showIfValue) {
      this.hidableTarget.hidden = false
    }
  }
}
