class VenueCardMapMarker {
  constructor(element) {
    this.element = element;
    this.lat = $(this.element).data("lat");
    this.lng = $(this.element).data("lng");
    this.pictures = $(this.element).data("pictures");
    this.previousPicture = $(this.element).find(".control-prev");
    this.nextPicture = $(this.element).find(".control-next");
    this.imageTag = $(this.element).find(".venue-picture-wrapper img");

    let currentIndex = 0;
    const loaded = {
      0: this.pictures[0]
    };

    const incrementIndex = (index) => {
      if (index < this.pictures.length - 1) {
        return index + 1;
      } else {
        return 0;
      }
    };

    const decrementIndex = (index) => {
      if (index > 0) {
        return index - 1;
      } else {
        return this.pictures.length - 1;
      }
    };

    const loadImage = (index) => {
      if (index >= this.pictures.length) {
        console.error(`There is no picture located at index ${index}`);
      } else {
        const url = this.pictures[index];
        const img = new Image();
        img.onload = function () {
          loaded[index] = url;
        };
        img.src = url;
      }
    };

    const showPreviousPicture = () => {
      currentIndex = decrementIndex(currentIndex);
      const pictureURL = this.pictures[currentIndex];
      this.imageTag.attr("src", pictureURL);
    };

    const showNextPicture = () => {
      currentIndex = incrementIndex(currentIndex);
      const pictureURL = this.pictures[currentIndex];
      // Display new picture
      this.imageTag.attr("src", pictureURL);

      // Load next 2 images
      const nextIndex = incrementIndex(currentIndex);
      const nextNextIndex = incrementIndex(nextIndex);
      if (!loaded[nextIndex]) {
        loadImage(nextIndex);
      }
      if (!loaded[nextNextIndex]) {
        loadImage(nextNextIndex);
      }
    };

    // Show next picture when next control is clicked
    $(this.element).find(".control-next").click(showNextPicture);
    $(this.element).find(".control-prev").click(showPreviousPicture);

    if (this.pictures.length !== 1) {
      loadImage(1);
    }
  }
}

export default VenueCardMapMarker;
