import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    $(this.element).tooltip({ boundary: 'window' })
  }

  disconnect() {
    $(this.element).tooltip("dispose")
  }
}
