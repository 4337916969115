import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "output"]

  initialize() {
    this.count()
  }

  get maxWords() {
    return this.data.get("max")
  }

  count() {
    this.outputTarget.textContent = `${this.inputTarget.value.length} / ${this.maxWords}`
  }
}
