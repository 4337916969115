import {Controller as BaseController} from "@hotwired/stimulus";
import {useDebounce} from "stimulus-use"

export default class extends BaseController {
	static targets = ['closeButton']
	static values = { name: String, forceRendering: Boolean }
  static debounces = ["setPosition"]

	connect() {
    useDebounce(this)
		if (Cookies.get(this.nameValue) && !this.forceRenderingValue) {
			this.element.classList.add('d-none');
			return;
		}

		this.setPosition();
		window.addEventListener("resize", () => this.setPosition());
	}

	setPosition() {
		const $nav = document.querySelector('nav.navbar')
		const isStickyNav = $nav && ($nav.classList.contains("sticky-top") || $nav.classList.contains("fixed-top"))
		const isFirst = this.element.offsetTop === 0

		if(isStickyNav && !isFirst) {
			this.element.style.top = `${$nav.offsetHeight}px`;
		} else {
			this.element.style.top = 0;
		}

		if(isFirst && isStickyNav) {
			$nav.style.top = `${this.element.offsetHeight}px`;
		}
	}

	close() {
		Cookies.set(this.nameValue, true);
		this.element.classList.add('fadeout');

		setTimeout(() => {
			this.element.classList.add('d-none');
		}, 600);
	}

}