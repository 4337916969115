import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    datesUnavailable: String,
    lowBudget: String,
    default: String
  }

  declineReasonUpdated(e) {
    switch (e.target.value) {
      case "dates_unavailable":
        this.hint = this.datesUnavailableValue
        break;
      case "low_budget":
        this.hint = this.lowBudgetValue
        break;
      default:
        this.hint = this.defaultValue
        break;
    }
  }

  // Getters

  get hintElement() {
    return document.querySelectorAll('.form-text.text-muted')[0]
  }

  // Setters

  /**
   * @param {string} message
   */
  set hint(message) {
    this.hintElement.textContent = message
  }
}
