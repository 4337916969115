import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["budgetTypeRadio", "budgetSection", "budgetTypeTab", "budgetFlexibleRadio", "budgetFlexibleTab", "priceSuggestionAlerts"]

  connect() {
    this.toggleBudgetTypeTabs()
    this.toggleBudgetFieldsVisibility()
    this.toggleBudgetFlexibleFieldsVisibility()
  }

  toggleBudgetTypeTabsVisibility(event) {
    this.data.set("activeBudgetType", event.target.id)
    this.toggleBudgetTypeTabs()
  }

  toggleBudgetFieldsVisibility() {
    this.budgetTypeSelected() ? this.showBudgetFields() : this.hideBudgetFields()
  }

  toggleBudgetFlexibleFieldsVisibility() {
    this.budgetFlexible() ? this.showBudgetFlexibleFields() : this.hideBudgetFlexibleFields()
  }

  togglePriceSuggestionAlert(event) {
    const checkPath = this.priceSuggestionAlertsTarget?.dataset?.checkPath
    if (!checkPath || event.target.value == "") return

    const params = {
      event: {
        first_budget: {
          amount: parseInt(event.target.value),
          type: this.data.get("activeBudgetType").replace("event_budget_type_budget_", "")
        }
      }
    }
    post(checkPath, { responseKind: "turbo-stream", body: JSON.stringify(params) })
  }

  // Private

  toggleBudgetTypeTabs() {
    this.budgetTypeTabTargets.forEach(tab => {
      if (this.data.get("activeBudgetType") === tab.dataset.toggleTabName) {
        tab.classList.remove("d-none")
      } else {
        tab.classList.add("d-none")
      }
    })
  }

  budgetTypeSelected() {
    return !!this.budgetTypeRadioTargets.find(radio => radio.checked)
  }

  showBudgetFields() {
    this.budgetSectionTarget.classList.remove("d-none")
  }

  hideBudgetFields() {
    this.budgetSectionTarget.classList.add("d-none")
  }

  budgetFlexible() {
    const checkedRadio = this.budgetFlexibleRadioTargets.find(radio => radio.checked)
    return checkedRadio && checkedRadio.value == "true"
  }

  showBudgetFlexibleFields() {
    this.budgetFlexibleTabTarget.classList.remove("d-none")
  }

  hideBudgetFlexibleFields() {
    this.budgetFlexibleTabTarget.classList.add("d-none")
  }
}
