import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["incompatibilityBlockToDisplay", "submit"]
  static values = { incompatibilities: Object }

  connect() {
    this.toggleImpacts()
  }

  check() {
    this.toggleImpacts()
  }

  // Private

  toggleImpacts() {
    if (this.invalidityCheck()) {
      this.impactIfInvalid()
    } else {
      this.impactIfValid()
    }
  }

  invalidityCheck() {
    const invalidityCheck = []

    Object.entries(this.incompatibilitiesValue).forEach(([id, value]) => {
      invalidityCheck.push(document.getElementById(id).value === value.toString())
    })

    return invalidityCheck.every(value => value === true);
  }

  impactIfInvalid() {
    if (this.hasSubmitTarget) this.submitTarget.disabled = true
    this.incompatibilityBlockToDisplayTargets.forEach((elt) => {
      elt.classList.remove("d-none")
    })
  }

  impactIfValid() {
    if (this.hasSubmitTarget) this.submitTarget.disabled = false
    this.incompatibilityBlockToDisplayTargets.forEach((elt) => {
      elt.classList.add("d-none")
    })
  }
}
