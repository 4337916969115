import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.removeErrors();
  }

  removeErrors() {
    this.element.querySelectorAll(".is-invalid").forEach((errorField) => {
      errorField.addEventListener("change", (e) => {
        if (errorField.value === "") {
          errorField.classList.remove("is-invalid");
          errorField.closest(".border-danger").classList.remove("border-danger");
          errorField.closest(".border-danger").querySelector(".invalid-feedback").remove();
        }
      });
    });
  }
}
