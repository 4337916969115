import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["downPaymentPercentage", "finalPaymentTerm"]

  toggle(e) {
    if (e.target.checked) {
      this.addOption(this.downPaymentPercentageTarget, "100", "100%")
      this.addOption(this.finalPaymentTermTarget, "before_event", "Au plus tard 7 jours avant événement")
    } else {
      if (this.downPaymentPercentageTarget.value === "100") this.updateSelect(this.downPaymentPercentageTarget, "80")
      if (this.finalPaymentTermTarget.value === "before_event") this.updateSelect(this.finalPaymentTermTarget, "on_receipt")
      this.removeOption(this.downPaymentPercentageTarget, "100")
      this.removeOption(this.finalPaymentTermTarget, "before_event")
      this.finalPaymentTermTarget
    }
  }

  addOption(target, value, text) {
    const options = Array.from(target.options)
    if (options.find(option => option.value === value)) return

    const option = document.createElement("option")
    option.value = value
    option.text = text
    target.add(option)
  }

  removeOption(target, value) {
    const options = Array.from(target.options)
    const optionToRemove = options.find(option => option.value === value)

    if (optionToRemove) target.remove(optionToRemove.index)
  }

  updateSelect(target, value) {
    target.value = value

    const event = new Event("change", { bubbles: true })
    target.dispatchEvent(event)
  }
}
