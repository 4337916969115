import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.autoSubmitTargets.forEach(input => {
      input.addEventListener("change", () => this.element.requestSubmit())
    })
  }

  get autoSubmitTargets() {
    return [
      ...this.element.querySelectorAll("input"),
      ...this.element.querySelectorAll("select")
    ]
  }
}
