import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["address", "name", "lat", "lng"]

  connect() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget)
    this.autocomplete.addListener('place_changed', this.locationChanged.bind(this))
  }

  preventSubmit(e) {
    if (e.key == "Enter") {
      e.preventDefault()
    }
  }

  fallbackToPreviousResult(e) {
    this.addressTarget.value = this.currentPlaceAddress
    this.nameTarget.value = this.currentPlaceName
  }

  // Private

  locationChanged() {
    const place = this.autocomplete.getPlace()

    if (place.geometry) {
      this.currentPlaceAddress = place.formatted_address

      if (this.hasNameTarget && place.vicinity) {
        this.currentPlaceName = place.vicinity
      } else if (place.name) {
        this.currentPlaceName = place.name
      } else {
        this.currentPlaceName = place.formatted_address
      }

      // address is automatically set by google Autocomplete
      // we need to manually update hidden name input
      if (this.hasNameTarget) {
        this.nameTarget.value = this.currentPlaceName
      }
      this.latTarget.value = place.geometry.location.lat()
      this.lngTarget.value = place.geometry.location.lng()
    }
  }

  // Getters

  get currentPlaceAddress() {
    return this.data.get("current-place-address")
  }

  get currentPlaceName() {
    return this.data.get("current-place-name")
  }

  // Setters

  set currentPlaceAddress(value) {
    this.data.set("current-place-address", value)
  }

  set currentPlaceName(value) {
    this.data.set("current-place-name", value)
  }
}
