import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="js-select2"
export default class extends Controller {
  static targets = ["fields"]

  connect() {
    this.initializeSelect2()
  }

  initializeSelect2() {
    $(this.fieldsTargets).each(function(){
      const config = {
        dropdownParent: $(this).parent(),
        width: "100%",
        theme: "bootstrap",
        allowClear: $(this).data('allowclear') !== undefined ? $(this).data('allowclear') : true,
        placeholder: $(this).data('placeholder'),
        minimumResultsForSearch: $(this).data('allowsearch') === false ? -1 : 0,
        insertTag: function (data, tag) {
          data.push({ id: tag.id, text: `+ Créer ${tag.text}` });
        },
      }
      $(this).select2(config)
    })
  }
}
