import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileInput", "submitButton"]

  static values = {
    aspectRatioError: {
      type: String,
      default: "Au moins une des photos sélectionnées est au format portrait. " +
                "Veuillez sélectionner uniquement des photos au format paysage."
    }
  }

  openFileBrowser() {
    this.fileInputTarget.click()
  }

  acceptFiles(e) {
    e.preventDefault()
  }

  handleDroppedFiles(e) {
    e.preventDefault()
    this.fileInputTarget.files = e.dataTransfer.files
    this.submitForm(e)
  }

  handleSelectedFiles(e) {
    const files = Array.from(this.fileInputTarget.files)

    Promise.all(files.map(this.checkImageAspectRatio)).then(values => {
      if (values.every(value => value)) {
        this.submitForm(e)
      } else {
        this.fileInputTarget.value = ""
        alert(this.aspectRatioErrorValue)
      }
    })
  }

  checkImageAspectRatio(file) {
    return new Promise((resolve) => {
      const image = new Image()
      image.src = window.URL.createObjectURL(file)
      image.onload = () => {
        const height = image.height
        const width  = image.width

        if (height / width > 1) {
          resolve(false)
        } else {
          resolve(true)
        }
      }
    })
  }

  initUpload(e) {
    const { detail } = e
    const { id, file } = detail
    document.querySelector('.upload-list').insertAdjacentHTML("beforeend", `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending rounded mt-4 col-md-4">
        <div class="picture-content">
          <img src="${URL.createObjectURL(file)}" style="width: 100%; height: 100%; object-fit: cover;" class="rounded" />

          <span class="progress picture-details picture-progress-bar">
            <div id="direct-upload-progress-${id}" class="progress-bar bg-warning pr-0" style="width: 0%; height:10px"></div>
          </span>
        </div>
      </div>
    `)
  }

  onFileuploadProgress(e) {
    const { id, progress } = e.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
  }

  onFileuploadEnd(e) {
    const { id } = e.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.classList.add("bg-success")
    progressElement.classList.remove("bg-warning")
  }

  submitForm() {
    this.submitButtonTarget.click()
  }

  allUploadsCompleted() {
    this.fileInputTarget.value = null
    this.fileInputTarget.disabled = false
    this.element.reset()

    let picturesHiddenInputs = this.element.querySelectorAll("input[type='hidden'][name='venue[pictures][]']")
    picturesHiddenInputs.forEach((pic) => pic.remove())

    let activityPicturesHiddenInputs = this.element.querySelectorAll("input[type='hidden'][name='activity[pictures][]']")
    activityPicturesHiddenInputs.forEach((pic) => pic.remove())
  }
}
