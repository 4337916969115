import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textInput"]

  connect() {
    tinyMCE.remove("[data-enrich-text-target='textInput']")
    tinyMCE.init(this.isInline() ? this.inlineOptions() : this.defaultOptions())
  }

  initialize() {
    // Fixes a bug that prevents adding links when editor is inside a bootstrap modal
    // https://stackoverflow.com/questions/18111582/tinymce-4-links-plugin-modal-in-not-editable
    // https://www.tiny.cloud/docs/tinymce/6/bootstrap-zip/#using-tinymce-in-a-bootstrap-dialog
    const targetClasses = ".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
    $(document).on("focusin", (event) => {
      if ($(event.target).closest(targetClasses) !== null || $(event.target).closest(targetClasses).length) {
        event.stopImmediatePropagation()
      }
    })
  }

  // Private

  isInline() {
    return this.textInputTarget.tagName != "TEXTAREA"
  }

  defaultOptions() {
    return {
      selector: "[data-enrich-text-target='textInput']",
      plugins: "code codesample emoticons image link preview table visualblocks wordcount",
      toolbar: "undo redo | styleselect | bold italic | link | emoticons | alignleft aligncenter alignright alignjustify | outdent indent | removeformat",
      convert_urls: false,
      remove_script_host: true,
      browser_spellcheck: true,
    }
  }

  inlineOptions() {
    return {
      selector: "[data-enrich-text-target='textInput']",
      plugins: 'autolink autoresize emoticons link quickbars',
      theme: 'silver',
      inline: true,
      quickbars_selection_toolbar: 'bold italic | link | emoticons',
      quickbars_insert_toolbar: false,
      browser_spellcheck: true,
      convert_urls: false,
      remove_script_host: true,
    }
  }
}
