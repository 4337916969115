import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    errorMessage: { type: String, default: "La modification a échouée, veuillez réessayer." }
  }
  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      ghostClass: this.data.get("ghostClass"),
      onEnd: this.onDragEnd.bind(this)
    })
  }

  async onDragEnd(event) {
    this.sortable.option("disabled", true)
    const data = {}
    data[this.resource] = this.sortable.toArray()

    try {
      await post(this.data.get("url"), { body: JSON.stringify(data) })
    } catch (error) {
      this.resetInitialState(event.oldIndex, event.newIndex)
      alert(this.errorMessageValue)
    }

    this.sortable.option("disabled", false)
  }

  resetInitialState(oldPosition, newPosition) {
    const children = this.element.children
    this.element.insertBefore(children.item(newPosition), children.item(oldPosition))
  }

  // Getter

  get resource() {
    return this.data.get("resource")
  }
}
