import { Controller as BaseController } from "@hotwired/stimulus";

export default class extends BaseController {
  static targets = ["button", "popover"];

  toggle() {
    if (this.popoverTarget.classList.contains("d-none")) {
      this.popoverTarget.classList.remove("d-none");
    } else {
      this.popoverTarget.classList.add("d-none");
    }
  }

  hide(event) {
    if (!this.element.contains(event.target)) this.popoverTarget.classList.add("d-none");
  }
}
