import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["counter"]

  increment(e) {
    e.preventDefault()
    this.counter++
  }

  decrement(e) {
    e.preventDefault()
    this.counter--
  }

  // Getters

  get counter() {
    return parseInt(this.counterTarget.value)
  }

  // Setters

  set counter(value) {
    if (this.data.get('max') !== null && value > this.data.get('max')) {
      return
    }
    if (this.data.get('min') !== null & value < this.data.get('min')) {
      return
    }
    this.counterTarget.value = value
  }
}
