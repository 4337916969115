import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"]
  connect() {
    this.initSelection()
    this.setSelection()
  }

  initSelection() {
    this.inputTargets.forEach((input) => {
      this.selection(input, parseInt(input.dataset.limit))
    })

  }

  setSelection(event) {
    if (event) {
      this.selection(event.target, parseInt(event.target.dataset.limit))
    }
  }

  selection(input, limit) {
    let elements = document.querySelectorAll(`input[name='${input.name}']`)
    let checkedElements = document.querySelectorAll(`input[name='${input.name}']:checked`)
    if (checkedElements.length === limit) {
      elements.forEach((elt) => { if(!elt.checked) { elt.disabled = true }})
    } else {
      elements.forEach((elt) => { elt.disabled = false })
    }
  }
}
