import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["lng", "lat", "address", "zipcode", "city", "country", "map", "form"]

  connect() {
    this.initAutocomplete()
    this.initMap()
    this.initMarker()

    if (this.lngTarget.value.length > 0 && this.latTarget.value.length > 0) {
      this.initVenueOnMap()
      this.formTarget.classList.remove("d-none")
    }
  }

  preventSubmit(e) {
    if (e.key == "Enter") {
      e.preventDefault()
    }
  }

  // Private

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget)
    this.autocomplete.addListener('place_changed', this.locationChanged.bind(this))
  }

  locationChanged() {
    this.formTarget.classList.remove("d-none")
    const place = this.autocomplete.getPlace()

    if (place.geometry) {
      this.lngTarget.value = place.geometry.location.lng()
      this.latTarget.value = place.geometry.location.lat()
    }

    this.addressTarget.value = place.name

    let i = 0
    while (i < place.address_components.length) {
      const type = place.address_components[i].types[0]
      if (type == "locality") {
        this.cityTarget.value = place.address_components[i].long_name
      } else if (type == "postal_code") {
        this.zipcodeTarget.value = place.address_components[i].short_name
      } else if (type == "country") {
        const countryName = place.address_components[i].long_name
        const matchingOption = this.findCountryOption(countryName)
        if (matchingOption !== null) {
          this.countryTarget.options[this.countryTarget.selectedIndex].selected = false
          matchingOption.selected = true
        }
      }
      i++
    }

    this.initVenueOnMap()
  }

  initMap() {
    this.center = new google.maps.LatLng(47.171603, 2.644132)
    this.map = new google.maps.Map(this.mapTarget, google.maps.Map.mapOptions(this.center))
  }

  initMarker() {
    this.marker = new google.maps.Marker(
      {
        icon: google.maps.MarkerIcon,
        map: this.map,
        draggable: true,
        visible: false
      }
    )
    this.marker.addListener('dragend', this.saveMarkerPosition.bind(this))
  }

  initVenueOnMap() {
    const venuePosition = new google.maps.LatLng(this.latTarget.value, this.lngTarget.value)
    this.map.setCenter(venuePosition)
    this.map.setZoom(15)
    this.marker.setPosition(venuePosition)
    this.marker.setVisible(true)
  }

  saveMarkerPosition() {
    this.latTarget.value = this.marker.position.lat()
    this.lngTarget.value = this.marker.position.lng()
  }

  findCountryOption(countryName) {
    for (let index = 0; index < this.countryTarget.options.length; index++) {
      const element = this.countryTarget.options[index];
      if (element.text == countryName) {
        return element
      }
    }
    return null
  }
}
