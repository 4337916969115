// We reserve Controller for the export name
import {Controller as BaseController} from "@hotwired/stimulus";

export default class extends BaseController {
  static values = {
    delay: {
      type: Number,
      default: 5000
    },
  }

  initialize () {
    this.hide = this.hide.bind(this)
  }

  connect() {
    this.show()
  }

  dismiss () {
    this.hide()
  }

  show () {
    this.timeout = setTimeout(this.hide, this.delayValue)
  }

  async hide () {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.element.style.opacity = '0'

    this.element.addEventListener('transitionend', () => this.element.remove());
  }
}
