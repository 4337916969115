import {Controller} from "@hotwired/stimulus"

(function() {

    if(!$.fn.select2) {
        return false
    }

    const Defaults = $.fn.select2.amd.require('select2/defaults');
    const Placeholder = $.fn.select2.amd.require('select2/selection/placeholder');
    const AllowClear = $.fn.select2.amd.require('select2/selection/allowClear');
    const SelectionSearch = $.fn.select2.amd.require('select2/selection/search');
    const Utils = $.fn.select2.amd.require('select2/utils');

    // parent method - apply
    const _defaultApply = Defaults.apply;

    Defaults.apply = function (options) {

        if (options.selectionAdapter) {
            if (options.placeholder != null) {
                options.selectionAdapter = Utils.Decorate(
                    options.selectionAdapter,
                    Placeholder
                );
            }
            options.selectionAdapter = Utils.Decorate(
                options.selectionAdapter,
                SelectionSearch
            );
            if (options.allowClear) {
                options.selectionAdapter = Utils.Decorate(
                    options.selectionAdapter,
                    AllowClear
                );
            }
        }

        // invoke parent method
        return _defaultApply.apply(this, arguments);
    }

})();

export default class extends Controller {
    connect() {
        const options = {
            tags: Boolean(this.data.get("tags")),
            theme: "bootstrap",
            width: this.data.get("width") || "style",
            placeholder: this.data.get("placeholder"),
            class: this.data.get("class"),
            selectAll: JSON.parse(this.data.get("selectAll")),
            limit: JSON.parse(this.data.get("limit")),
        }

        this.customizeSelect2()

        $(this.element).select2({
            ...options,
            selectionAdapter: $.fn.select2.amd.require("select2/selection/customSelectionAdapter"),
        })

        const { selectAll } = options

        if(selectAll) {
            const errors = []
            if(!selectAll.selectedText) {
                errors.push("You must provide a selectedText option for selectAll")
            }

            if(!selectAll.unselectedText) {
                errors.push("You must provide a unselectedText option for selectAll")
            }

            if(errors.length > 0) {
                console.error(errors)
                return false
            }

            this.insertSelectAllButton(options.selectAll)
        }
    }

    insertSelectAllButton(selectAll) {
        const $select2 = $(this.element).next('.select2')
        const $parent = $select2.parent()
        const isDisabled = $(this.element).attr('disabled') === 'disabled'

        if(isDisabled) {
            return false
        }

        const $selectAll = $(
            `<button class="btn btn-link mt-2 mb-4">${selectAll.selectedText}</button>`
        )

        $selectAll.on("click", (e) => {
            e.preventDefault()
            const $options = $(this.element).find("option")
            const $selectedOptions = $(this.element).find("option:selected")

            if ($options.length === $selectedOptions.length) {
                $(this.element).val(null).trigger("change")
                $selectAll.text(selectAll.selectedText)
            } else {
                $(this.element).val($options.map((_, option) => option.value)).trigger("change")
                $selectAll.text(selectAll.unselectedText)
            }
        })

        $(this.element).on('change', () => {
            const $options = $(this.element).find("option")
            const $selectedOptions = $(this.element).find("option:selected")

            if ($options.length === $selectedOptions.length) {
                $selectAll.text(selectAll.unselectedText)
            } else {
                $selectAll.text(selectAll.selectedText)
            }
        })

        $parent.append($selectAll)
    }

    customizeSelect2() {
        // Thx to :
        // https://github.com/andreivictor/select2-customSelectionAdapter/tree/master
        $.fn.select2.amd.define('select2/selection/customSelectionAdapter',
            [
                'jquery',
                'select2/selection/base',
                'select2/selection/eventRelay',
                'select2/utils',
            ], function ($, BaseSelection, EventRelay, Utils) {

                function CustomSelection($element, options) {
                    CustomSelection.__super__.constructor.apply(this, arguments);
                    this.options = options;
                }

                Utils.Extend(CustomSelection, BaseSelection);

                CustomSelection.prototype.render = function () {
                    const $selection = CustomSelection.__super__.render.call(this);

                    $selection.addClass('select2-selection--multiple select2-selection--multiple--custom');

                    $selection.html(
                        '<ul class="select2-selection__rendered select2-container"></ul>'
                    );

                    // define the container for the tags
                    this.$selectionTagsContainer = $(
                        '<div class="tags--container"></div>'
                    );

                    this.$selectionTagsContainer.addClass('select2-container--custom-selection select2-container--' + this.options.get('theme'));

                    this.$selectionTags = $selection.clone().addClass('select2-selection--custom').removeClass('select2-selection__rendered');

                    this.$selectionTagsContainer.html(this.$selectionTags);

                    this.$selectionTagsContainer.addClass('select2-container--empty');

                    if (this.options.get('selectionContainer')) {
                        this.$selectionTagsContainer.appendTo(this.options.get('selectionContainer'));
                    } else {
                        this.$selectionTagsContainer.insertAfter(this.$element.next('.select2'));
                    }

                    setTimeout(() => {
                        this.$searchContainer.addClass(this.options.get('class'))
                        this.$searchContainer.find('.select2-search__field').css('width', '100%');
                        this.$selection.find('.select2-selection__rendered').css('width', '100%');
                    }, 100);

                    return $selection;
                };


                CustomSelection.prototype.bind = function (container, $container) {
                    const self = this;

                    CustomSelection.__super__.bind.apply(this, arguments);

                    this.$selection.on('click', function (evt) {
                        self.trigger('toggle', {
                            originalEvent: evt
                        });
                    });

                    this.$selectionTags.on(
                        'click',
                        '.select2-selection__choice__remove',
                        function (evt) {
                            // Ignore the event if it is disabled
                            if (self.options.get('disabled')) {
                                return;
                            }

                            const $remove = $(this);
                            const $selection = $remove.parent();

                            const data = $selection.data('data');

                            self.trigger('unselect', {
                                originalEvent: evt,
                                data: data
                            });
                        }
                    );

                    container.on('enable', function () {
                        self.$selectionTagsContainer.removeClass('select2-container--disabled');
                    });

                    container.on('disable', function () {
                        self.$selectionTagsContainer.addClass('select2-container--disabled');
                    });

                };

                CustomSelection.prototype.clear = function () {
                    this.$selectionTagsContainer.addClass('select2-container--empty');
                    // we need this line to remove the existing .select2-selection__clear button
                    this.$selection.find('.select2-selection__rendered').empty();
                    this.$selectionTags.find('.select2-selection__rendered').empty();
                };

                CustomSelection.prototype.display = function (data, container) {
                    const  template = this.options.get('templateSelection');
                    const escapeMarkup = this.options.get('escapeMarkup');

                    return escapeMarkup(template(data, container));
                };

                CustomSelection.prototype.selectionContainer = function () {
                    const $container = $(
                        '<li class="select2-selection__choice">' +
                        '<span class="select2-selection__choice__remove" role="presentation">' +
                        '</span>' +
                        '</li>'
                    );

                    return $container;
                };

                CustomSelection.prototype.update = function (data) {
                    this.clear();

                    if (data.length === 0) {
                        return;
                    }

                    this.$selectionTagsContainer.removeClass('select2-container--empty');

                    const $selections = [];

                    const limit = this.options.get('limit') || 0;

                    const isLimit = (data.length > limit) && limit;

                    const items = isLimit ? data.slice(0, limit) : data;

                    for (let d = 0; d < items.length; d++) {
                        const selection = items[d];

                        let $selection = this.selectionContainer();
                        let formatted = this.display(selection, $selection);


                        $selection.prop('title', selection.title || selection.text);

                        $selection.data('data', selection);

                        Utils.StoreData($selection[0], 'data', selection);

                        $selection.append(formatted);
                        $selections.push($selection);
                    }


                    if (isLimit) {
                        let $selection = this.selectionContainer();
                        let formatted = this.display({
                            text: `+ ${data.length - limit}`,
                        }, $selection);
                        $selection.find('.select2-selection__choice__remove').remove();
                        $selection.addClass('more-items');
                        // Pass selected and all items to display method
                        // which calls templateSelection
                        formatted =
                        $selection.append(formatted);
                        $selections.push($selection);
                    }

                    const $rendered = this.$selectionTags.find('.select2-selection__rendered');

                    Utils.appendMany($rendered, $selections);

                    // Re-append placeholder
                    if (this.placeholder && this.placeholder.text) {
                        this.$search.attr('placeholder', this.placeholder.text);
                    }

                    setTimeout(() => {
                        this.$search.focus();
                    }, 100);

                };

                // Decorate after the adapter is built
                return Utils.Decorate(CustomSelection, EventRelay);
            }
        );
    }
}

